<template>
  <div>
    <el-table :data="users" style="width: 100%">
      <el-table-column prop="id" label="uid" width="100" />
      <el-table-column label="Name" width="160">
        <template slot-scope="scope">
          <router-link
            :to="{
              name: 'ProfileForAdmin',
              params: { id: scope.row.id }
            }"
          >
            {{ scope.row.name }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="顯示於清單" width="80">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Click to toggle show bio"
            placement="top"
            popper-class="tooltipColor"
          >
            <span
              @click="
                () => {
                  $emit('toggleShowBio', scope.row.id);
                }
              "
            >
              <span v-if="scope.row.showBill === 1" key="0">
                <i class="far fa-check-square action-icon" />
              </span>
              <span v-else key="1">
                <i class="far fa-square action-icon" />
              </span>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="顯示於上課請求" width="80">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="Click to toggle show request"
            placement="top"
            popper-class="tooltipColor"
          >
            <span
              @click="
                () => {
                  $emit(
                    'toggleShowRequest',
                    scope.row.id,
                    scope.row.show_on_invitation_list
                  );
                }
              "
            >
              <span v-if="scope.row.show_on_invitation_list === 1" key="0">
                <i class="far fa-check-square action-icon" />
              </span>
              <span v-else key="1">
                <i class="far fa-square action-icon" />
              </span>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="照片" width="70">
        <template slot-scope="scope">
          <span key="check" v-if="scope.row.photo_url" style="color: #42a16a">
            <i class="fas fa-check" />
          </span>
          <span key="cross" v-else style="color: red">
            <i class="fas fa-times" />
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="school" label="School" width="120" />
      <el-table-column
        prop="college_graduation_year"
        label="Year"
        width="130"
      >
        <template slot-scope="scope">
          {{ getTeacherGrade(scope.row.college_graduation_year) }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="studentsCount"
        label="Private Students"
        width="140"
      >
        <template slot-scope="scope">
          <router-link :to="{ name: 'privateLessons' }">
            {{ scope.row.studentsCount }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="lastLessonDate" label="Last Lesson" width="140">
        <template slot-scope="scope">
          <router-link :to="getClassLessonLogRouter(scope.row)">
            {{ scope.row.lastLessonDate }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="hoursAvailable"
        label="Hour Available"
        width="120"
      />
      <el-table-column prop="phone" label="phone" width="140" />
      <el-table-column prop="lineId" label="line" width="100" />
      <el-table-column prop="wechat" label="Wechat" width="100" />
      <el-table-column v-if="isRoleAdmin()" width="250">
        <div slot="header">
          Notes
          <el-tooltip
            content="只有管理員看得見這個備註"
            placement="top"
            popper-class="tooltipColor"
          >
            <span class="action-icon"><i class="fas fa-question-circle"/></span>
          </el-tooltip>
        </div>
        <template slot-scope="scope">
          <div>
            {{ scope.row.notes }}
            <el-tooltip
              content="编辑"
              placement="top"
              popper-class="tooltipColor"
            >
              <b
                class="text-success"
                style="cursor: pointer;"
                @click="editNotes(scope.$index)"
              >
                <i class="fas fa-edit" />
              </b>
            </el-tooltip>
          </div>
          <!-- <div v-show="!scope.row.notes">
            <el-tooltip
              content="點擊添加"
              placement="top"
              popper-class="tooltipColor"
            >
              <span type="text" @click="editNotes(scope.$index)">
                {{ scope.row.notes }}
                <i class="fas fa-edit" />
              </span>
            </el-tooltip>
          </div> -->
        </template>
      </el-table-column>
      <el-table-column label="action" width="140" align="center">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            content="User Profile"
            placement="top"
            popper-class="tooltipColor"
          >
            <router-link
              :to="{
                name: 'ProfileForAdmin',
                params: { id: scope.row.id }
              }"
            >
              <i class="fas fa-eye action-icon" />
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            :content="
              `Click to mark ${scope.row.isActive ? 'inactive' : 'active'}`
            "
            placement="top"
            popper-class="tooltipColor"
          >
            <span
              key="inactive"
              v-if="scope.row.isActive"
              @click="$emit('toggleUserActive', scope.row.id)"
            >
              <i class="far fa-stop-circle action-icon" />
            </span>
            <span
              key="active"
              v-else
              @click="$emit('toggleUserActive', scope.row.id)"
            >
              <i class="far fa-play-circle action-icon" />
            </span>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="Notes" :visible.sync="showNotes" width="80%">
      <el-input
        type="textarea"
        :rows="8"
        placeholder="請輸入內容"
        v-model="teacherNotes"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showNotes = false">取 消</el-button>
        <el-button type="primary" @click="saveNotes">
          更新
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import roleMixin from "@/mixins/role";
import profileTest from "@/mixins/profileTest";
import { EnumPrivateSessions } from "@/enums";

export default {
  mixins: [profileTest, roleMixin],
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showNotes: false,
      editIndex: null,
      teacherNotes: null
    };
  },
  methods: {
    editNotes(index) {
      console.log(index);
      this.showNotes = true;
      this.editIndex = index;
      this.teacherNotes = this.users[index].notes;
    },
    getClassLessonLogRouter(teacher) {
      const courseSessionId = teacher.lastLessonSessionId;
      const privateSessionIds = [
        ...EnumPrivateSessions.privateLessonIds,
        ...EnumPrivateSessions.counselingIds
      ];
      if (privateSessionIds.includes(courseSessionId)) {
        let privateType = null;
        if (EnumPrivateSessions.privateLessonIds.includes(courseSessionId)) {
          privateType = "private-lessons";
        } else if (
          EnumPrivateSessions.counselingIds.includes(courseSessionId)
        ) {
          privateType = "counseling";
        }

        return {
          name: "privateLessonsTeachingLogDetail",
          params: {
            privateType,
            courseId: teacher.lastLessonClassId,
            teachingLogId: teacher.lastTeachingId
          }
        };
      }

      return {
        name: "sessionClassesTeachingLogDetail",
        params: {
          courseId: teacher.lastLessonClassId,
          teachingLogId: teacher.lastTeachingId
        }
      };
    },
    async saveNotes() {
      const newObj = {
        id: this.users[this.editIndex].id,
        newProfile: { notes: this.teacherNotes }
      };
      await this.$store.dispatch("profile/updateTeacherProfileByAdmin", newObj);
      this.users[this.editIndex].notes = this.teacherNotes;
      this.showNotes = false;
    }
  }
};
</script>
